.home-container {
  width: 100vw;
  height: 100vh;
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;
}

/* .home-smoke-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
} 

.home-smoke-bg .smoke img {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  mix-blend-mode: screen;
}  */

.smoke {
  mix-blend-mode: screen;

  position: absolute;
  bottom: -8px;
  left: 0;

  height: auto;
  width: 100vw;
  z-index: 9;
}

.smoke video {
  width: 100% !important;
  height: auto !important;
}

.home-car-smoke {
  position: absolute;
  right: 3%;
  bottom: 24%;
  mix-blend-mode: screen;
  transform: rotate(400deg);
}

.home-car-smoke img {
  width: 146px;
  height: 165px;
}

.header-upper-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;

  z-index: -99;
}

.header-upper-bg img {
  width: 100vw;
  height: 120vh;
}

.header-lower-bg {
  position: absolute;
  bottom: 0;
  width: 100vw;

  z-index: -99;
}

.header-lower-bg img {
  width: 100vw;
  height: 75vh;
}

.loader-car-animation {
  position: relative;
  width: 100vw;
  height: 100vh;
  background-color: black;
  
}

.loader-car-animation img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 100vw;
  height: 100vh;
object-fit: contain;
  z-index: 999999;
}

/* bg image end here */
.text {
  position: absolute;
  top: 50%;
  left: 12%;

}

.text > :nth-child(1) {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 15.1412px;
  line-height: 18px;
  text-align: center;

  background: #95c11f;
  border-radius: 2.67198px;
  text-decoration: none;
  padding: 13px 25px;

  color: #ffffff;
}

.text > :nth-child(2) {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;

  color: #ffffff;
}

.car-img {
  position: absolute;
  bottom: 0%;
  right: 20px;
}

.car-img img {
  width: 100%;
  height: 740px;
}

@media screen and (max-width: 1024px) {
  .car-img img {
    width: 100%;
    height: 100%;
  }

  .home-container {
    width: 100vw;
    height: 75vh;
    position: relative;
    overflow-x: hidden;
    overflow-y: hidden;
  }
  .header-upper-bg img {
    width: 100vw;
    height: 80vh;
  }
  .header-lower-bg img {
    width: 100vw;
    height: 60vh;
  }
  .header-lower-bg img {
    width: 100vw;
    height: 60vh;
  }
  .navbar-nav .nav-item .nav-link {
    padding: 5px !important;
    position: relative;
  }
}

@media screen and (max-width: 768px) {
  .home-container {
    width: 100%;
    height: 500px;

    /* background-image: url("../../../public/assets/images/header-bg-2.png"); */
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 39%,
        rgba(0, 0, 0, 0.45) 70%
      ),
      url("../../../public/assets/images/header-bg-2.webp");
    background-position: cover;
    object-fit: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }

  .smoke {
    display: none !important;
  }

  .home-smoke-bg {
    display: none;
  }
  .car-img {
    position: absolute;
    bottom: -6%;
    left: -10%;
  }
  .car-img img {
    width: 100%;
    height: 100%;
  }
  .home-smoke-bg div img {
    width: 100%;
    height: 600px;
    object-fit: cover;
    mix-blend-mode: screen;
    border: none;
    outline: none;
  }
  .home-car-smoke {
    display: none !important;
  }
  .text {
    position: absolute;
    top: 40%;
    left: 6%;
    text-align: center;
  }
}

@media screen and (max-width: 600px) {
  .car-img {
    position: absolute;
    bottom: 0%;
    left: -20%;
  }
}

@media screen and (min-width: 1919px) {
  .car-img img {
    width: 100%;
    height: 80%;
  }
  .car-img {
    z-index: 99;
  }
  .home-car-smoke {
    position: absolute;
    right: 5%;
    bottom: 32%;
    mix-blend-mode: screen;
    transform: rotate(400deg);
  }
  /* .home-car-smoke {
    bottom: 27%;
    mix-blend-mode: screen;
    position: absolute;
    right: 4%;
    -webkit-transform: rotate(400deg);
    transform: rotate(400deg);
  } */
  .smoke {
    z-index: 999;
    position: absolute;
    bottom: -4.5px;
    left: 0;
  }
}

.Toastify__toast-container{
  z-index: 99999999999 !important;
}
@media screen and (min-width: 2500px) {
  .home-car-smoke {
    position: absolute;
    right: 4%;
    bottom: 26%;
    /* top: 32%; */
    mix-blend-mode: screen;
    transform: rotate(400deg);
  }
  .home-container {
    width: 100vw;
    height: 88vh;
  }
  .header-upper-bg img {
    width: 100vw;
    height: 102vh;
  }

  .header-lower-bg img {
    width: 100vw;
    height: 62vh;
  }
}

@media (min-width: 1430px) and (max-width: 1450px) {
  .home-container {
    width: 100vw;
    height: 90vh;
  }
}

@media (min-width: 1270px) and (max-width: 1290px) {
  .home-container {
    width: 100vw;
    height: 90vh;
  }
}

/* animation for desktop screen */

@media screen and (min-width: 1200px) {
  /* hero explore more btn animation */
  [data-aos="home-hero-ltr-animation"] {
    opacity: 0;
    transform: translateX(-100px);
  }
  [data-aos="home-hero-ltr-animation"].aos-animate {
    opacity: 1;
    transform: translateX(0);
  }

  /* hero car animation */
  [data-aos="home-hero-rtl-animation"] {
    opacity: 0;
    transform: translateX(100px);
  }
  [data-aos="home-hero-rtl-animation"].aos-animate {
    opacity: 1;
    transform: translateX(0);
  }
}
