.downloadnow-sec {
  height: 868px;
  position: relative;
  .download-inner {
    position: absolute;
    left: 22.4%;
    z-index: 20;
    top: 250px;
    max-width: 829px;
    width: 100%;
    border-radius: 22px;
    border: 2px solid #fff;
    padding: 90px 80px;
    h1 {
      color: #95c11f;
      font-family: "Killer boots";
      font-size: 66.903px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-bottom: 43px;
    }
    .download-btns {
      display: flex;
      align-items: center;
      column-gap: 25px;
      a {
        border: none;
        padding: 0px;
        border-radius: 10px;
        width: 223.322px;
        height: 65.819px;
        overflow: hidden;
        background: transparent;
        img {
          width: 100%;
          object-fit: cover;
        }
      }
    }
    .download-mobile {
      position: absolute;
      left: 70%;
      z-index: 20;
      top: -122px;
    }
  }
  .download-layer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    mix-blend-mode: color-dodge;

    z-index: 1;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

@media screen and (max-width: 1600px) {
  .downloadnow-sec {
    height: 700px;
    .download-inner {
      left: 15%;
      top: 208px;
      max-width: 600px;
      padding: 70px 50px;
      h1 {
        font-size: 40px;
        margin-bottom: 20px;
      }
      .download-btns {
        column-gap: 20px;
        button,
        a {
          width: 150px;
          height: 50px;
        }
      }
      .download-mobile {
        left: 70%;
        top: -90px;
        width: 400px;
        height: 500px;
      }
    }
  }
}

@media screen and (max-width: 780px) {
  .downloadnow-sec {
    height: unset;
    padding: 50px 20px;
    width: 100%;
    .download-inner {
      width: 100%;
      position: unset;
      padding: 20px;
      left: unset;
      top: unset;
      border-radius: 10px;
      h1 {
        font-size: 30px;
        margin-bottom: 15px;
      }
      .download-btns {
        column-gap: 15px;
        button,
        a {
          width: 120px;
          height: 45px;
          overflow: hidden;
          background: transparent;
          border-radius: 5px;
          img {
            width: 100%;
            object-fit: cover;
          }
        }
      }
      .download-mobile {
        position: unset;
        left: unset;
        top: unset;
        right: unset;
        bottom: unset;
        display: none;
      }
    }
  }
}
