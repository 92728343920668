.tokken-bg {
  width: 100vw;
  /* height: 100vh; */
  background: url("../../../public/assets/images/tokken/gear\ 1.webp");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;

  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;
  padding-bottom: 50px;
}

.tokken-title {
  text-align: center;
  /* padding-top: 40px; */
}

.tokken-animated-bg {
  position: absolute;
  bottom: 0px;
  left: 0px;

  width: 100vw;
}

.tokken-animated-bg img {
  width: 100vw;
  height: 100vh;

  object-fit: cover;
}

.tokken-animated-text-bg {
  position: absolute;
  left: 0;
  bottom: 0;
}

.tokken-animated-text-bg img {
  width: 100vw;
}
.tokken-content {
  /* margin-left: 50px; */
  text-align: center;
  padding-top: 80px;
  width: 100%;
  margin: 0 auto;
}

.tokken-content > div > img {
  width: 55%;
}

.tokken-content > div > p {
  font-family: "Rajdhani";
  font-style: normal;
  font-weight: 700;
  font-size: 38.0708px;
  line-height: 49px;
  /* identical to box height */

  text-align: center;
  text-transform: uppercase;
  color: #cccccc;

  margin-top: 30px;
}

.tokken-clickbtn {
  position: relative;
  z-index: 99;

  text-align: center;
  background-color: white;
}

.tokken-btn-container > button {
  border: none;
  outline: none;
  text-align: center;
  border-radius: 5px;
  padding: 6px 20px;
  font-size: 20px;
}

.tokken-clickbtn {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 700;
  font-size: 28.6262px;
  line-height: 36px;
  padding: 10px 22px;
  /* identical to box height */

  text-align: center;

  color: #161616;
}

.tokken-animated-content {
  width: 90%;
  text-align: center;
  color: white;

  margin: 80px auto;
}

.animated-tokken {
  padding: 15px;

  background: rgba(255, 255, 255, 0.08);
  border-radius: 11.7616px;

  border: 1px solid rgba(231, 209, 17, 0.8);
  box-shadow: 0 4px 8px 0
    rgba(231, 209, 17, 0.2, 0 6px 20px 0 rgba(231, 209, 17, 0.8));
}

/* .animated-tokken :hover {
  border: 2px solid green;
  
  cursor: pointer;
}  */

.tokken-animated-text > h2 {
  font-family: "Rajdhani";
  font-style: normal;
  font-weight: 700;
  font-size: 18.2108px;
  line-height: 23px;
  text-align: center;
  text-transform: uppercase;

  color: #cccccc;
}

.tokken-animated-text > p {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 14px;
  color: #ffffff;
}

.tokken-btn-container.text-center {
  margin-top: 40px;
}

.hover-text-wrapper {
  margin: 120px auto;
  display: flex;
  gap: 15px;
  width: 80%;
}

.hover-text-l-f {
  flex: 50%;
  background: rgba(255, 255, 255, 0.08);
  border-radius: 11.7616px;
  /* padding: 35px 10px;
  margin: 5px; */
  margin: 57px 0px !important;
  padding: 0 !important;
  box-sizing: border-box;
}

.hover-text-r-f {
  flex: 50%;
  background: rgba(255, 255, 255, 0.08);
  border-radius: 11.7616px;
  /* padding: 35px 10px;
  margin: 5px; */
  margin: 57px 0px !important;
  padding: 0 !important;
  box-sizing: border-box;
}

.hover-text-image {
  position: relative;
  padding: 30px 0px !important;
}

.hover-text-content {
  border-left: 1px solid #524f4f;
  padding: 20px 12px;
}

.hover-text-content-r {
  border-left: none;
  border-right: 1px solid #524f4f;
  padding-left: 35px;
}

.hover-text-left-hand {
  position: absolute;
  margin-top: -375px;
  margin-left: -205px;
}

.hover-text-left-hand img {
  /* height: 340px;
  width: 351px; */

  opacity: 0;
  transition: 0.3s ease-in-out;
}

.hover-text-l-f:hover {
  box-shadow: 0px 0px 23px 5px rgb(231 209 17 / 44%);
}

.hover-text-l-f:hover .hover-text-left-hand img {
  opacity: 1;
}

.hover-text-right-hand {
  position: absolute;
  margin-top: -359px;
  margin-left: -158px;
}

.hover-text-right-hand img {
  /* height: 359px;
  width: 372px; */

  opacity: 0;
  transition: 0.3s ease-in-out;
}

.right-hand-image {
  margin-left: -19px;
}

.hover-text-r-f:hover {
  box-shadow: 0px 0px 23px 5px rgb(231 209 17 / 44%);
}

.hover-text-r-f:hover .hover-text-right-hand img {
  opacity: 1;
  overflow: hidden;
}

.hover-text-image > :nth-child(1) {
  width: 165px;
  /* height: 140px; */
  /* margin-top: 15px; */
}

.hover-text-content h3 {
  font-family: "Rajdhani";
  font-style: normal;
  font-weight: 700;
  font-size: 18.2108px;
  line-height: 23px;
  text-transform: uppercase;
  color: #cccccc;
}

.hover-text-content p {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 17px;

  color: #ffffff;
}

@media screen and (max-width: 1280px) {
  .tokken-animated-bg {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  
  .hover-text-wrapper {
    margin: 0px auto;

  }
  
  .hover-text-l-f {
    margin: 40px 0px !important;

  }
  
  .hover-text-r-f {

    margin: 0px 0px !important;

  }

  .tokken-title img {
    width: 80%;
  }

  .tokken-content > div > p {
    font-family: "Rajdhani";
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 32px;
    padding: 0px 15px;

    margin-top: 0px !important;
  }

  .tokken-bg {
    width: 100vw;
    height: auto;
    padding-bottom: 20px;
  }

  .hover-text-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .hover-text-left-hand {
    display: none;
  }
  .hover-text-right-hand {
    display: none;
  }
  .col-lg-9.hover-text-content {
    text-align: center;
    border-left: none !important;
  }
  .hover-text-content-r {
    border-left: none;
    border-right: none !important;
    padding-left: 35px;
  }
  .hover-text-image {
    text-align: center;
  }
  .tokken-btn-container.text-center {
    padding: 0px 0px 30px 0px;
  }

  .hover-text-content p {
    font-family: "Quicksand";
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 17px;
    color: #ffffff;
    padding: 0px 16px;
  }
}

@media screen and (min-width: 1535px) {
  .animated-tokken {
    padding: 63px;
    background: rgba(255, 255, 255, 0.08);
    border-radius: 11.7616px;
    border: 1px solid rgba(231, 209, 17, 0.8);
    box-shadow: 0 4px 8px 0
      rgba(231, 209, 17, 0.2, 0 6px 20px 0 rgba(231, 209, 17, 0.8));
  }
}

@media screen and (min-width: 1919px) {
  .tokken-btn-container.text-center {
    margin-top: 140px;
  }
  .tokken-title {
    text-align: center;
  }
  .hover-text-wrapper {
    margin: 250px auto;
    display: flex;
    gap: 50px;
    width: 80%;
  }
  .hover-text-content {
    border-left: 1px solid #524f4f;
    padding: 40px 20px;
  }

  .hover-text-l-f {
    padding: 0 !important;
    margin: 39px !important;
    box-sizing: border-box !important;
  }
  .hover-text-image {
    padding: 30px 0px !important;
  }
  .hover-text-r-f {
    padding: 0 !important;
    margin: 39px !important;
    box-sizing: border-box !important;
  }
  .hover-text-content-r {
    border-left: none;
    border-right: 1px solid #524f4f;
    padding-left: 35px;
  }
}

/* ontext animation end here */

[data-aos="new-animation-tokken"] {
  opacity: 0;
  transition-property: transform, opacity;
}
[data-aos="new-animation-tokken"].aos-animate {
  opacity: 1;
}

@media screen and (min-width: 1200px) {
  [data-aos="new-animation-tokken"] {
    transform: translateX(-100px);
  }
  [data-aos="new-animation-tokken"].aos-animate {
    transform: translateX(0);
  }
}
