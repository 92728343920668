.chart-wrapper {
  position: relative;
}

.animated-chart-text {
  position: absolute;
  bottom: 0;
  left: 0;

  z-index: -99;
}

.animated-chart-text img {
  height: 100vh;
  width: 100vw;

  z-index: -99;
}

.chart-bg {
  /* background: url("../../../public/assets/images/joinimage/animate-bg-2.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover; */
  width: 100%;
  height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  /* background-color: rgba(0, 0, 0, 0.1); */
}

.chart-bg .chart-title {
  text-align: center;
  margin-top: 20px;
}

.chart-bg .chart-title > img {
  width: 60%;
}

.chart-bg .chart-title p {
  padding-top: 20px;
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 400;
  font-size: 28.8px;
  line-height: 36px;
  text-align: center;

  color: #ffffff;
}

.chart-bg .chart-container {
  padding-top: 120px;
  padding-bottom: 30px;
  margin: 0 auto;
}

.chart-card-container {
  width: 95%;
  margin: 0 auto;
}

.chart-bg .join-chart-heading {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 700;
  font-size: 19px;
  line-height: 34px;
  /* identical to box height */

  color: #ffffff;
  text-align: center;
}

.chart-bg .join-chart-card {
  background: url(".././../../public/assets/images/joinimage/Rectangle\ 7.png");
  /* background-position: center center; */
  background-repeat: no-repeat;
  background-size: contain;
  width: 180px;
  height: 260px;
}

.chart-bg .card-body .card-title {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  text-align: center;

  color: #ffffff;
}

.chart-bg .card-body .card-text {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 700;
  font-size: 17.686px;
  line-height: 22px;
  text-align: center;

  color: #bcff0a;
}

.green-car {
  position: relative;

  mix-blend-mode: screen;
}

.car-smoke {
  position: absolute;
  bottom: 0;
  left: 36%;
  z-index: -99;
}

.car-smoke img {
  width: 200px;
  height: 200px;
}

.car-smoke-2 {
  position: absolute;
  top: 26%;
  right: 10%;
  z-index: -99;
}

.car-smoke-2 img {
  width: 100px;
  height: 150px;
}

.car-smoke-3 {
  position: absolute;
  top: 33%;
  left: 0%;
  z-index: -99;
}

.car-smoke-3 img {
  width: 100px;
  height: 150px;
}

.piechart-size-wrapper {
  width: 160px;
  height: 170px;
}

.lastmonth {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 500;
  font-size: 9px;
  line-height: 9px;

  color: #ffffff;
}
.pastuser {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 700;
  font-size: 7px;
  line-height: 15px;
  /* identical to box height */

  color: #ffffff;
}

.percent {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 400;
  font-size: 5px;
  line-height: 5px;
}

.graph-text {
  position: relative;
}
.graph-percentage {
  position: absolute;
  top: 40%;
  left: 50%;

  font-family: "Quicksand";
  font-size: 12px;
  font-weight: bold;
}

.graph-percentage-lower {
  position: absolute;
  top: 40%;
  left: 35%;

  font-family: "Quicksand";
  font-size: 12px;
  font-weight: bold;
}
.last-graph-text {
  position: relative;
}
.last-graph-text-percentage {
  position: absolute;
  top: 50%;
  left: 40%;

  font-family: "Quicksand";
  font-size: 12px;
  font-weight: bold;
}
.last-bottom-graph-text-percentage {
  position: absolute;
  top: 50%;
  left: 40%;

  font-family: "Quicksand";
  font-size: 12px;
  font-weight: bold;
}
.second-card-overlay {
  background-color: rgba(0, 0, 0, 0.8);
  margin-top: -33px;
  z-index: 11111;
  position: relative;
}

path.recharts-sector {
  stroke: none;
}

.green-car-image {
  width: 90%;
}

@media screen and (max-width: 1024px) {
  .chart-card-container {
    width: 100%;
    margin: 0 auto;
  }
  .chart-bg .join-chart-heading {
    font-size: 16px;
  }
  .chart-bg .join-chart-card {
    width: 168px;
    height: 260px;
  }
  .chart-bg .card-body .card-title {
    font-size: 15px;
  }
  .chart-bg .card-body .card-text {
    font-size: 15px;
  }
  .piechart-size-wrapper {
    margin-left: -14px;
  }
}

@media screen and (max-width: 768px) {
  .animated-chart-text {
    display: none;
  }
  .chart-bg {
    width: 100%;
    height: auto;

    /* background-image: url("../../../public/assets/images/joinimage/chart-mobile-bg.png"); */

    background-color: rgba(0, 0, 0, 0.1);
  }
  .chart-bg .chart-title > img {
    width: 70%;
  }

  .chart-bg .chart-title p {
    font-size: 28.8px;
    line-height: 36px;
  }

  .chart-bg .join-chart-card {
    /* width: 280px; */
    height: 260px;
    margin: 20px auto;
  }
  .car-smoke,
  .car-smoke-2,
  .car-smoke-3 {
    display: none;
  }

  .chart-bg .chart-title {
    text-align: center;
    margin-top: 40px;
  }
  .chart-bg .chart-container {
    padding-top: 30px;
    padding-bottom: 30px;
    margin: 0 auto;
  }
  .green-car {
    position: relative;
    text-align: center !important;
  }
  .green-car-image {
    width: 75% !important;
  }
  .green-car-mobile-bg {
    background-image: url("../../../public/assets//images/joinimage/chart-car-mobile-bg.png");
    background-repeat: no-repeat;
    background-size: cover;

    /* position: relative;
    z-index: -999; */
  }
  .piechart-size-wrapper {
    margin-left: -14px;
  }
}

@media screen and (max-width: 600px) {
  .chart-bg .join-chart-card {
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 260px;
    height: 300px;
  }
  .piechart-size-wrapper {
    margin-left: 30px;
  }
}

@media screen and (min-width: 1919px) {
  .chart-bg .chart-title {
    text-align: center;
    margin-top: -80px;
  }
  .chart-bg .chart-title > img {
    width: 80%;
  }

  .chart-bg .chart-container {
    padding-top: 150px;
    padding-bottom: 30px;
    margin: 0 auto;
    text-align: center;
  }
  .chart-bg .join-chart-card {
    background: url(".././../../public/assets/images/joinimage/Rectangle\ 7.png");
    /* background-position: center center; */
    background-repeat: no-repeat;
    background-size: contain;
    width: 260px;
    height: 400px;
  }
  .pastuser {
    font-family: "Quicksand";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 15px;
    color: #ffffff;
  }
  .percent {
    font-family: "Quicksand";
    font-style: normal;
    font-weight: 400;
    font-size: 9px;
    line-height: 5px;
    color: #ffffff;
  }
  .car-smoke {
    position: absolute;
    bottom: 0;
    left: 45%;
    z-index: -99;
  }
  .car-smoke-2 {
    position: absolute;
    top: 32%;
    right: 8%;
    z-index: -99;
  }
  .car-smoke-3 {
    position: absolute;
    top: 43%;
    left: 7%;
    z-index: -99;
  }
  .piechart-size-wrapper {
    width: 240px;
    height: 300px;
  }
  .lastmonth-wrapper {
    margin-top: 20px;
  }
  .lastmonth {
    font-family: "Quicksand";
    font-style: normal;
    font-weight: 500;
    font-size: 9px;
    line-height: 9px;
    color: #ffffff;
    margin: 13px 0px;
  }

  .barchart-first {
    margin: 50px 0px;
  }
  .barchart-first-margin {
    padding-left: 12px;
  }
  .pielastchart-lower {
    margin-top: 16px;
  }
  .pielastchart-lower-space {
    margin-top: 15px;
  }
  .third-one.card.join-chart-card .recharts-wrapper:first-child svg {
    transform: scale(2);
  }
  .first-one.card.join-chart-card .recharts-wrapper:first-child svg {
    transform: scale(1.6);
  }
}

@media screen and (min-width: 1200px) {
  [data-aos="new-animation-chart"] {
    transform: translateY(-150px);
    opacity: 0;
  }
  [data-aos="new-animation-chart"].aos-animate {
    transform: translateX(0);
    opacity: 1;
  }
  [data-aos="textbg-animation-chart"] {
    opacity: 0;
    transform: rotateX(300deg);
    transform-origin: bottom;
  }
  [data-aos="textbg-animation-chart"].aos-animate {
    opacity: 1;
    transform: rotateX(0);
    transform-origin: bottom;
  }

  [data-aos="chart-ltr-animation"] {
    opacity: 0;
    transform: translateX(-100px);
  }
  [data-aos="chart-ltr-animation"].aos-animate {
    opacity: 1;
    transform: translateX(0);
  }
  [data-aos="chart-rtl-animation"] {
    opacity: 0;
    transform: translateX(100px);
  }
  [data-aos="chart-rtl-animation"].aos-animate {
    opacity: 1;
    transform: translateX(0);
  }
}

@media (min-width: 900px) and (max-width: 1000px) {
  .chart-wrapper {
    position: relative;
    margin-top: 200px;
  }
  .chart-bg .join-chart-heading {
    text-align: start;
  }
  .green-car-image {
    width: 55% !important;
  }
  .car-smoke,
  .car-smoke-2,
  .car-smoke-3 {
    display: none;
  }
}
