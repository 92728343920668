.footer-sec {
  position: relative;
  padding: 178px 0px 40px 0px;
  .footer-inner {
    max-width: 704px;
    margin: 0px auto;
    position: relative;
    z-index: 9;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 50px;

    input {
      border-radius: 4.409px;
      border: 1.386px solid #fff;
      padding: 21px 34px;
      color: #fff;
      font-family: Quicksand;
      font-size: 18.478px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      background: transparent;
      outline: none;
      width: 100%;
    }
    button {
      border-radius: 3.762px;
      background: #95c11f;
      width: fit-content;
      padding: 17px 43px;
      color: #000;
      text-align: center;
      font-family: "Killer boots";
      font-size: 22.173px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      border: none;
      margin-bottom: 50px;
    }
    .social-links {
      display: flex;
      align-items: center;
      column-gap: 57px;
      a {
        cursor: pointer;
      }
    }
    p {
      color: #fff;
      text-align: center;
      font-family: Quicksand;
      font-size: 14.207px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin: 0px;
      span {
        color: #95c11f;
      }
    }
  }
  .footer-layer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url("../../../public/assets/images/footer/ezgif.com-gif-maker.gif");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    mix-blend-mode: color-burn;

    z-index: 1;
  }
}

@media screen and (max-width: 1400px) {
  .footer-sec {
    padding: 100px 0px 40px 0px;
    .footer-inner {
      max-width: 500px;
      row-gap: 30px;

      input {
        padding: 15px 20px;
        font-size: 16px;
      }
      button {
        padding: 15px 30px;
        font-size: 18px;
        margin-bottom: 20px;
      }
      .social-links {
        display: flex;
        align-items: center;
        column-gap: 40px;
        a {
          cursor: pointer;
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .footer-sec {
    padding: 50px 20px 40px 20px;
    .footer-inner {
      row-gap: 20px;

      input {
        padding: 15px;
      }
      button {
        padding: 10px 20px;
        font-size: 16px;
        margin-bottom: 20px;
      }
      .social-links {
        display: flex;
        column-gap: 20px;
        a {
          cursor: pointer;
          width: 20px;
          height: 20px;
          img {
            width: 100%;
            object-fit: cover;
          }
        }
        .medium-icon {
          width: 100px;
          img {
            width: 100%;
            object-fit: cover;
          }
        }
      }
      p {
        color: #fff;
        text-align: center;
        font-family: Quicksand;
        font-size: 14.207px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        span {
          color: #95c11f;
        }
      }
    }
  }
}
