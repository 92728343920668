.footer-bg-outer {
  /* background: linear-gradient(
    180deg,
    rgba(15, 15, 15, 0.5) 0%,
    rgba(0, 0, 0, 0.5) 100%
  ); */
  position: relative;
  height:45vh;
  width: 100vw;

  overflow-x: hidden;
  overflow-y: hidden;
}

.footer-bg {
  height: auto;
  background-image: url("../../../public/assets/images/footer/ezgif.com-gif-maker.gif");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  mix-blend-mode: color-burn;
  min-height: 45vh;
  /* padding: 50px 0px; */
  /* position: absolute; */

  overflow-x: hidden;
}

.footer-content {
  /* padding-top: 100px; */
  max-width: 70%;
  margin: 0 auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-bottom: 0px;

  /* margin-top: 70px; */
  /* display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; */
}

::placeholder {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 400;
  font-size: 18.4777px;
  line-height: 23px;
  color: #ffffff;
}
.footer-content .footer-email input {
  background-color: transparent;
  width: 700px;
  height: 64px;
  outline: none;
  border: none;
  color: #ffffff;
  border: 2px solid white;
  border-radius: 5px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  animation: footerupanimation 1s ease 3s 1 normal forwards;
padding-left: 20px;
  margin-top: 50px;
  font-size: 18px;
}

.footer-content .footer-socials img {
  padding: 10px 20px;
}

.footer-content .footer-website-name {
  font-family: "Bebas Neue", cursive;
  font-style: normal;
  font-weight: 400;
  font-size: 33.1018px;
  line-height: 40px;
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
}

.footer-content .footer-copyright {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 400;
  font-size: 14.2066px;
  line-height: 18px;
  text-align: center;
  color: #ffffff;
  /* margin-bottom: 25px; */
}

@media screen and (max-width: 600px) {
  .footer-content {
    padding-top: 0px;
    max-width: 80%;
    margin: 0 auto;
  }
  .footer-content .footer-email input {
    width: 100%;
  }
  .footer-content .footer-socials {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px !important;
  }
  .footer-content .footer-socials img {
    padding: 2px 7px;
    width: 40px ;
    height: 40px;
    object-fit: contain;
  }
  .footer-content .footer-socials a:last-child{
    width: 100px;
  }
  /* .medium-icon{
    width: 100% !important;
  } */

}

@media screen and (max-width: 400px) {
  .footer-content {
    padding-top: 0px;
    max-width: 90%;
    margin: 0 auto;
  }
  .footer-content .footer-email input {
    width: 300px;
  }
  .footer-content .footer-socials {
    width: 350px;
    padding-left: 30px;
  }
  .footer-content .footer-socials img {
    padding: 2px 7px;
    width: 60px;
  }
  .footer-content .footer-website-name {
    font-size: 22px;
    line-height: 24px;
  }
  .footer-content .footer-copyright {
    font-size: 12px;
    line-height: 16px;
  }
}
/* 
@media screen and (min-width: 1919px) {
  .footer-bg-outer {
    height: 60vh;
  }

  .footer-bg {
    min-height: 60vh;
  }
} */
