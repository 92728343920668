@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Montserrat:wght@500&family=Offside&family=Quicksand&family=Rajdhani:wght@500&display=swap");
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden;
  background-image: linear-gradient(
      180deg,
      #0f0f0f 80%,
      rgba(0, 0, 0, 0.8) 100%
    ),
    url("../public/assets/images/Background\ \(1\).webp");
  background-color: rgba(0, 0, 0, 0.6);
  /* mix-blend-mode: luminosity; */
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
}

@font-face {
  font-family: "AGRESSIVE";
  src: url("../public/assets/fonts/agressive-font/Agressive.ttf");
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: "Killer boots";
  src: url("../public/assets/fonts//killer-boots/KILLERBOOTS.TTF");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Expansiva";
  src: url("../public/assets/fonts/expansiva/Expansiva.otf");
  font-weight: 100;
  font-style: normal;
}
