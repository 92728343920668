.roadmap-wrapper {
  position: relative;
}

.roadmap-locater {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* opacity: 0; */
}

.roadmap-locater img {
  transform: scale(0.7);
}

.roadmap-bg {
  width: 100%;
  height: auto;
  overflow: hidden;
  padding: 40px 0px;
}

.roadmap-bg .roadmap-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.roadmap-bg .roadmap-title > :nth-child(2) {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 400;
  font-size: 28.8px;
  line-height: 36px;
  text-align: center;

  color: #ffffff;
}

.roadmap-timeline-bg {
  /* background: url("../../../public/assets/images/roadmap/Infographic_317\ [Converted]-01\ 1.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; */
  /* padding: 100px 10px; */

  width: 100%;
  height: auto;
}

.roadmap-timeline-bg > :nth-child(1) {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: end;
}

.roadmap-timeline-bg > :nth-child(1) > img {
  width: 95%;
}

.roadmap-timeline-bg > :nth-child(2) {
  width: 100%;
  padding-left: 30px;
  padding-top:30px;
  display: flex;
  justify-content: center;
}

.roadmap-timeline-bg > :nth-child(2) > img {
  width: 50%;
}

.roadmap-timeline-bg > :nth-child(3) {
  width: 100%;
  padding-top: 30px;
  display: flex;
  justify-content: start;
}

.roadmap-timeline-bg > :nth-child(3) > img {
  width: 63%;
}

/* @media screen and (max-width: 1025px) {
  .roadmap-timeline-bg > :nth-child(2) {
    width: 100%;
    padding-left: 10px;
    padding-top: 12px;
    display: flex;
    justify-content: center;
  }

  .roadmap-timeline-bg > :nth-child(3) {
    width: 100%;
    padding-top: 2px;
    display: flex;
    justify-content: start;
  }

  .roadmap-timeline-bg > :nth-child(1) > img {
    width: 95%;
  }

  .roadmap-timeline-bg > :nth-child(2) > img {
    width: 90%;
  }

  .roadmap-timeline-bg > :nth-child(3) > img {
    width: 90%;
  }
  .roadmap-locater-mobile {
    display: none !important;
  }
  .roadmap-wrapper {
    position: relative;
    background-image: url("../../../public//assets//images/roadmap/bg-map.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }
} */

/* @media screen and (max-width: 769px) {
  .roadmap-bg .roadmap-title {
    justify-content: space-evenly;
    align-items: center;
  }

  .roadmap-bg .roadmap-title > img {
    width: 70%;
  }

  .roadmap-bg .roadmap-title > :nth-child(2) {
    font-family: "Quicksand";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 36px;
    color: #ffffff;
  }
  .roadmap-locater-mobile {
    display: none !important;
  }

  .roadmap-wrapper {
    position: relative;
    background-image: url("../../../public//assets//images/roadmap/bg-map.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }
} */

/* [data-aos="new-animation-roadmap"] {
  opacity: 0;
  transition-property: transform, opacity;
}
[data-aos="new-animation-roadmap"].aos-animate {
  opacity: 0;
}

[data-aos="new-animation-roadmap-img-1"] {
  opacity: 0;
}
[data-aos="new-animation-roadmapimg-1"].aos-animate {
  opacity: 1;
}
[data-aos="new-animation-roadmap-img-3"] {
  opacity: 0;
}
[data-aos="new-animation-roadmapimg-3"].aos-animate {
  opacity: 1;
} */


@media screen and (max-width:769px) {


  
.roadmap-timeline-bg > :nth-child(1) > img {
  width: 95%;
}

.roadmap-timeline-bg > :nth-child(2) {
  padding-left: 0px;
  padding-top:10px;
  justify-content: end;
  padding-right: 10px;
}

.roadmap-timeline-bg > :nth-child(2) > img {
  width: 70%;
}

.roadmap-timeline-bg > :nth-child(3) {
  padding-top: 10px;
}

.roadmap-timeline-bg > :nth-child(3) > img {
  width: 80%;
}
  
}
@media screen and (min-width: 1200px) {
  [data-aos="roadmap-locator-animation"] {
    opacity: 1;
    /* transform: translate(0); */
  }
  [data-aos="roadmap-locator-animation"].aos-animate {
    transform: translate(200px);
    opacity: 0;
  }
  [data-aos="roadmap-map-animation"] {
    transform: scale(1);
  }
  [data-aos="roadmap-map-animation"].aos-animate {
    transform: scale(1.9);
  }
  [data-aos="new-animation-roadmap-img-1"] {
    transform: translateX(200px);
    opacity: 0;
  }
  [data-aos="new-animation-roadmap-img-1"].aos-animate {
    transform: translateX(1px);
    opacity: 1;
  }
  [data-aos="new-animation-roadmap-img-3"] {
    transform: translateX(-200px);
    opacity: 0;
  }
  [data-aos="new-animation-roadmap-img-3"].aos-animate {
    transform: translateX(1px);
    opacity: 1;
  }
  [data-aos="new-animation-roadmap-img-2"] {
    opacity: 0;
  }
  [data-aos="new-animation-roadmap-img-2"].aos-animate {
    opacity: 1;
  }
}

/* @media screen and (min-width: 1919px) {
  .roadmap-timeline-bg > :nth-child(1) > img {
  }
  .roadmap-timeline-bg > :nth-child(2) > img {
    max-width: 59%;
    margin-left: 20px;
  }
  .roadmap-timeline-bg > :nth-child(3) > img {
  }
} */
