.intro-container {
  background: url("../../../public/assets/images/intro/bg-intro.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100vw;
  height: 100vh;
}
.wrapper {
  width: 40%;
  margin: 0 auto;
}
.wrapper > :nth-child(1) {
  max-width: 100%;
}
.wrapper > :nth-child(2) {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  text-align: center;

  color: #ffffff;
}

.wrapper > :nth-child(3) {
  text-decoration: none;
  font-family: "AGRESSIVE";
  font-style: italic;
  font-weight: 700;
  font-size: 26.4254px;
  line-height: 30px;
  text-align: center;
  border: 2.53846px solid #95c11f;
  border-radius: 8.46154px;
  padding: 15px 30px;

  color: #ffffff;
}

.wrapper > :nth-child(4) {
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .wrapper {
    width: 90%;
    margin: 0 auto;
  }
}

@media screen and (min-width: 1920px) {
  .wrapper > :nth-child(2) {
    width: 70%;
    margin: 0 auto;
  }
}
