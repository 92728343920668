.team-bg-latest {
  /* background-color: rgba(0, 0, 0, 0.5); */
  padding: 60px 0px;
  overflow-x: hidden;
  overflow-y: hidden;

  /* margin-top: 120px; */
}
.team-bg {
  width: 100%;
  height: auto;
}

.team-content {
  width: 100%;
  margin: 0 auto;
  padding-top: 100px;
}
.team-content .team-title {
  text-align: center;
}

.team-content .team-leader {
  text-align: center;
  margin: 100px 0;
}

.team-content .team-leader span {
  padding: 5px 15px;
}

.team-card {
  justify-content: center;
  background: linear-gradient(
    90deg,
    rgba(149, 193, 31, 0.43) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  border-left: 8px solid #95c11f;

  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

  max-width: 250px;
  height: 333px;

  position: relative;
}

.team-image-overlay {
  position: absolute;
  bottom: -1px;
  left: 0px;

  /* z-index: -99; */

  width: 250px;
  height: 333px;

  background: linear-gradient(360deg, #000000 25.19%, rgba(0, 0, 0, 0) 100%);
}

.team-card img {
  height: 305px;
  width: 15rem;
  margin-top: -50px;
  margin-left: 5px;
}

.team-desination {
  font-family: "Killer boots";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 40px;
  color: #95c11f;
  opacity: 0.8;
  mix-blend-mode: overlay;
  transform: rotate(-90deg);
  position: absolute;
  top: 26px;
  left: 0px;
}

.team-profile-pic {
  margin-top: -60px;
}

.team-name {
  font-family: "Bebas Neue";
  font-style: normal;
  font-weight: normal;
  font-size: 26px;
  line-height: 37px;
  color: #ffffff;

  /* margin-top: 25px; */
  z-index: 9999;
  position: absolute;
}

.team-part {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 400;
  font-size: 13.6013px;
  line-height: 17px;
  color: wheat;

  z-index: 9999;
  position: absolute;
  bottom: 10px;
}

.team-only-mobile-section {
  display: none !important;
}

@media screen and (max-width: 1024px) {
  .team-image-overlay {
    position: absolute;
    bottom: -1px;
    left: 0px;
    width: 218px;
    height: 333px;
    background: linear-gradient(360deg, #000000 25.19%, rgba(0, 0, 0, 0) 100%);
  }
}

@media screen and (max-width: 768px) {
  .team-card {
    justify-content: center;
    background: linear-gradient(
      90deg,
      rgba(149, 193, 31, 0.43) 0%,
      rgba(0, 0, 0, 0) 100%
    );
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    border-left: 8px solid #95c11f;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    max-width: 250px;
    height: 333px;
    position: relative;
    margin: 30px;
  }
  .team-image-overlay {
    position: absolute;
    bottom: -1px;
    left: 0px;
    width: 250px;
    height: 333px;
    background: linear-gradient(360deg, #000000 25.19%, rgba(0, 0, 0, 0) 100%);
  }
}

@media screen and (max-width: 500px) {
  .team-card {
    margin: 50px auto;
  }

  .team-content .team-leader img {
    width: 140px;
    height: 52px;
  }
  .team-content .team-leader span img {
    width: 25px;
    height: 100px;
  }
  .team-only-laptop-section {
    display: none !important;
  }
  .team-only-mobile-section {
    display: block !important;
  }

  .team-only-mobile-section .slick-prev {
    left: 0px !important;
  }

  .team-only-mobile-section .slick-next {
    right: 0px !important;
  }
  .team-image-overlay {
    position: absolute;
    bottom: -1px;
    left: 0px;
    width: 250px;
    height: 333px;
    background: linear-gradient(360deg, #000000 25.19%, rgba(0, 0, 0, 0) 100%);
  }
}

@media screen and (max-width: 350px) {
  .team-content .team-leader img {
    width: 115px;
    height: 40px;
  }
}

@media (min-width: 900px) and (max-width: 1000px) {
  .team-card {
    justify-content: center;
    background: linear-gradient(
      90deg,
      rgba(149, 193, 31, 0.43) 0%,
      rgba(0, 0, 0, 0) 100%
    );
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    border-left: 8px solid #95c11f;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    max-width: 250px;
    height: 333px;
    position: relative;
    margin: 30px;
  }
  .team-image-overlay {
    position: absolute;
    bottom: -1px;
    left: 0px;
    width: 250px;
    height: 333px;
    background: linear-gradient(360deg, #000000 25.19%, rgba(0, 0, 0, 0) 100%);
  }
}

/* [data-aos="team-animation-title"] {
  opacity: 0;
  transition-property: transform, opacity;
}
[data-aos="team-animation-title"].aos-animate {
  opacity: 1;
}

[data-aos="team-animation-team-leader"] {
  opacity: 0;
  transition-property: transform, opacity;
}
[data-aos="team-animation-team-leader"].aos-animate {
  opacity: 1;
}

[data-aos="team-animation-team-member"] {
  opacity: 0;
  transition-property: transform, opacity;
}
[data-aos="team-animation-team-member"].aos-animate {
  opacity: 1;
} */

@media screen and (min-width: 1200px) {
  [data-aos="team-animation-title"] {
    opacity: 1;
    transform: translateY(300px);
  }
  [data-aos="team-animation-title"].aos-animate {
    opacity: 1;
    transform: translateY(0px);
  }
  [data-aos="team-animation-team-leader"] {
    opacity: 0;
    transform: translateY(-200px);
  }
  [data-aos="team-animation-team-leader"].aos-animate {
    opacity: 1;
    transform: translateY(0px);
  }
  [data-aos="team-animation-team-member"] {
    opacity: 0;
    transform: translateY(-200px);
  }
  [data-aos="team-animation-team-member"].aos-animate {
    opacity: 1;
    transform: translateY(0px);
  }
}
