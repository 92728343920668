.partners-bg {
  width: 100%;
  height: 70vh;

  overflow-x: hidden;
  overflow-y: hidden;

  /* background-image: url("../../../public/assets/images/body-bg.png"); */
  background-color: rgba(0, 0, 0, 0.2);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.partners-content {
  /* padding-top: 230px; */
  width: 70%;
  margin: 0px auto;
}

.partners-content .partenrs-btn {
  display: flex;
  justify-content: center;
  align-items: center;

  margin-bottom: 80px;
}

.partners-content .partners-logo {
  text-align: center;
  padding-top: 80px;
}

.partners-content .partners-logo-img {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .partners-content .partners-logo-img > div > img {
  width: 200px;
  height: 90px;
} */

.partners-logo-img-mobile {
  display: none !important;
}

@media screen and (max-width: 600px) {
  .partners-bg {
    width: 100%;
    height: auto;
  }

  .partners-content {
    padding-top: 40px;
    width: 100%;
    margin: 0px auto;
  }

  .partners-content .partners-logo {
    text-align: center;
    padding-top: 0px;
  }

  .partners-content .partenrs-btn {
    margin-bottom: 30px;
  }

  .partners-content .partners-logo-img {
    display: none !important;
  }

  .partners-content .partenrs-btn img {
    width: 80%;
  }

  .partners-logo-img-mobile > div {
    padding-top: 50px;
  }
  .partners-logo-img-mobile {
    display: block !important;
  }
}
