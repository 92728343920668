.nfts-bg {
  width: 100%;
  height: 100vh;

  overflow-x: hidden;
  overflow-y: hidden;

  position: relative;

  /* background-color: rgba(0, 0, 0, 0.1); */
  /* background-image: url("../../../public/assets/images/nftsimage/nfts-bg-2.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover; */
}

.nfts-animated-bg-2 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  z-index: -999;
}

.nfts-animated-bg-2 img {
  /* transform: scale(2); */
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  transition: 1s ease-in-out;
}

/* .nfts-animated-bg-2:hover img {
  transform: scale(1.2);
} */

.nft-icon {
  width: 70px;
  height: 70px;
}

.nfts-animated-bg {
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translate(-50%, 0%);
}

.nfts-bg .nfts-title {
  text-align: center;
  padding-top: 70px;
}

.nfts-bg .nfts-title p {
  padding-top: 20px;
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 36px;
  text-align: center;
  color: #ffffff;

  padding-top: 30px;
  padding-bottom: 50px;
}

.slider-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.slider-container .icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 15px;
}

.slider-container .icon-container .speed-container {
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.slider-container .icon-container .speed-container > :nth-child(1) {
  font-family: "Rajdhani";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: #95c11f;
}

.slider-container .icon-container .speed-container > :nth-child(2) {
  font-family: "Expansiva";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 0px;
  /* identical to box height */

  text-align: center;

  color: #ffffff;
}

.icon-container.desktop-middle-icon-2,
.icon-container.desktop-middle-icon {
  padding: 10px 0px;
}

.image-main-car {
  width: 40%;
  margin-top: 50px;
}

.nfts-only-mobile-section {
  display: none !important;
}

.slick-prev:before {
  content: "" !important;
}
.slick-next:before {
  content: "" !important;
}

button.slick-arrow.slick-prev {
  background-image: url("../../../public/assets/images/nftsimage/ep_arrow-up\ \(1\).webp");
  color: white;
  height: 50px;
  width: 50px;
  font-weight: bold;
  border-radius: 50%;
  text-align: center;
  background-size: contain;
  display: block;
  z-index: 999;
}

button.slick-arrow.slick-next {
  background-image: url("../../../public/assets//images//nftsimage/ep_arrow-up.webp");
  color: white;
  height: 50px;
  width: 50px;
  font-weight: bold;
  border-radius: 50%;
  text-align: center;
  background-size: contain;
}

@media screen and (max-width: 1369px) {
  .nfts-text-image {
    max-width: 70%;
    height: auto;
  }
  .nfts-animated-bg {
    position: absolute;
    bottom: -10px;
    left: 65%;
    transform: translate(-50%, 0%);
  }
}

@media screen and (max-width: 1200px) {
  .nfts-animated-bg-2 {
    display: none;
  }
}
@media screen and (min-width: 1024px) {
  .desktop-middle-icon {
    margin-left: -80px;
  }

  .desktop-middle-icon-2 {
    margin-right: -80px;
  }

  .desktop-margin {
    margin-left: 100px;
  }

  .desktop-margin2 {
    margin-right: 100px;
  }
}
@media screen and (max-width: 768px) {
  .slider-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }

  .mobile-responsive {
    display: flex;
  }

  .slider-container .icon-container {
    gap: 5px;
  }

  .slider-container .icon-container img {
    width: auto;
  }

  .slider-container .icon-container .speed-container > :nth-child(1) {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
  }

  .slider-container .icon-container .speed-container > :nth-child(2) {
    font-weight: 500;
    font-size: 11px;
    line-height: 18px;
  }
  .nfts-only-laptop-section {
    display: none !important;
  }
  .nfts-only-mobile-section {
    background-image: url("../../../public/assets/images/nftsimage/nfts-only-mobile-section.webp");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    display: block !important;

    width: 100%;
    height: 600px;
  }

  .nfts-only-mobile-section .slick-prev,
  .nfts-only-mobile-section .slick-next {
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 30% !important;
    display: block;
    width: 20px;
    height: 20px;
    padding: 0;
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    background: transparent;
  }

  button.slick-arrow.slick-prev {
    background-image: url("../../../public/assets/images/nftsimage/ep_arrow-up\ \(1\).webp");
    color: white;
    height: 30px;
    width: 30px;
    font-weight: bold;
    border-radius: 50%;
    text-align: center;
    background-size: contain;
    display: block;
    z-index: 999;
  }

  button.slick-arrow.slick-next {
    background-image: url("../../../public/assets//images//nftsimage/ep_arrow-up.webp");
    color: white;
    height: 30px;
    width: 30px;
    font-weight: bold;
    border-radius: 50%;
    text-align: center;
    background-size: contain;
  }

  .nfts-only-mobile-section .slick-prev {
    left: 10px !important;
  }

  .nfts-only-mobile-section .slick-next {
    right: 10px !important;
  }

  .nfts-bg .nfts-title p {
    padding-top: 20px;
    font-family: "Quicksand";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 36px;
    text-align: center;
    color: #ffffff;
    padding-top: 30px;
    padding-bottom: 50px;
  }
  .image-main-car {
    width: 55%;
    margin: 0 auto;
  }
}
@media screen and (max-width: 600px) {
  .nfts-bg .nfts-title img {
    width: 70%;
  }
  .nfts-bg {
    width: 100%;
    height: auto;
  }
  .nfts-only-mobile-section {
    background-image: url("../../../public/assets/images/nftsimage/nfts-only-mobile-section.webp");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    width: 100%;
    height: 600px;
  }

  .image-main-car {
    width: 70%;
    margin: 0 auto;
  }
}

[data-aos="nft-animation-bg"] {
  opacity: 0;
  transition-property: transform, opacity;
}
[data-aos="nft-animation-bg"].aos-animate {
  opacity: 1;
}

@media screen and (min-width: 1200px) {
  [data-aos="nft-animation-bg"] {
    opacity: 1;
    transform: scale(0.5);
  }
  [data-aos="nft-animation-bg"].aos-animate {
    opacity: 1;
    transform: scale(1);
  }
}

@media screen and (min-width: 1919px) {
  .nfts-bg {
    width: 100%;
    height: 100vh;

    overflow: hidden;
  }
  .nfts-bg .nfts-title {
    padding-top: 200px;
  }
  .image-main-car {
    width: 33%;
  }
  .icon-container.desktop-middle-icon-2,
  .icon-container.desktop-middle-icon {
    padding: 45px 0px;
  }
}
