.navbar-wrapper {
  width: 90%;
  margin: 0 auto;
  position: relative;
  z-index: 9999999999;
}
.navbar-nav {
  position: relative;
  z-index: 9999999999;
}

.navbar-nav li.nav-item {
  padding: 0px 6px;
}

.navbar-toggler {
  width: 30px;
  height: 20px;
  position: relative;
  transition: 0.5s ease-in-out;
}

.navbar-toggler,
.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
  outline: none;
  box-shadow: none;
  border: 0;
  position: relative;
}

.navbar-toggler span {
  margin: 0;
  padding: 0;
}

.toggler-icon {
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  background: white;
  border-radius: 1px;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: 0.25s ease-in-out;
}

.middle-bar {
  margin-top: 0px;
}

/* State when the navbar is collapsed */

.navbar-toggler.collapsed .top-bar {
  position: absolute;
  top: 0px;
  transform: rotate(0deg);
}

.navbar-toggler.collapsed .middle-bar {
  opacity: 1;
  position: absolute;
  top: 10px;
  filter: alpha(opacity=100);
}

.navbar-toggler.collapsed .bottom-bar {
  position: absolute;
  top: 20px;
  transform: rotate(0deg);
}

/* when navigation is clicked */

.navbar-toggler .top-bar {
  top: inherit;
  transform: rotate(135deg);
}

.navbar-toggler .middle-bar {
  opacity: 0;
  top: inherit;
  filter: alpha(opacity=0);
}

.navbar-toggler .bottom-bar {
  top: inherit;
  transform: rotate(-135deg);
}

/* Color of 3 lines */

.navbar-toggler.collapsed .toggler-icon {
  background-color: white;
}

.navbar-nav .nav-item .nav-link {
  padding: 15px;
  position: relative;
}

/* .navbar-nav .nav-item .nav-link::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0%;
  transform: translateX(10%);
  width: 0%;
  width: 100%;
  height: 100%;
  background: url("../../../../public/assets/images/header/nav-item-bg.png");
  background-size: fill;
  background-position: center;
  background-repeat: no-repeat;

  border-left: none;
  transition: all 0.4s linear;
} */

.navbar-nav .nav-item .nav-link:hover {
  background: linear-gradient(
    270deg,
    #95c11f 2.29%,
    rgba(255, 255, 255, 0) 100%
  );
  border-radius: 0px 25.5px 25.5px 0px;
}

.active {
  background: linear-gradient(
    270deg,
    #95c11f 2.29%,
    rgba(255, 255, 255, 0) 100%
  );
  border-radius: 0px 25.5px 25.5px 0px;
  border: 2px solid
    linear-gradient(270deg, #95c11f 2.29%, rgba(255, 255, 255, 0) 100%);
  border-left: none;
}

.navbar-nav li a {
  font-family: "Offside";
  font-style: normal;
  font-weight: 200;
  font-size: 13px;
  line-height: 18px;

  color: #ffffff;
  /* text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25); */
}

.logo-wrapper {
  width: 220px;
}

@media screen and (max-width: 768px) {
  .navbar-nav .nav-item .nav-link:hover {
    border: none;
    padding-right: 20px !important;
  }

  .navbar-nav {
    background-color: black;
    width: 100%;
    position: relative;
    z-index: 99999999;
    border-radius: 5px;
    padding: 15px;
  }
  button.navbar-toggler.d-flex.d-lg-none.flex-column.justify-content-around {
    position: relative;
    z-index: 9999;
  }
  .logo-wrapper {
    width: 150px;
  }
  .navbar-nav li.nav-item{
    width: fit-content;
 }


}

@media screen and (min-width: 1920px  ) {
  .logo-wrapper {
    width: 280px;
  }
  .navbar-nav li a {
    font-family: "Offside";
    font-style: normal;
    font-weight: 200;
    font-size: 18px;
    line-height: 18px;

    color: #ffffff;
  }
  .navbar-nav li.nav-item {
    padding: 0px 10px;
  }
}
@media screen and (max-width: 1500px  ) {
  .logo-wrapper {
    width: 140px;
  }
  .navbar-nav li a {
    font-size: 16px;
  }
  .navbar-wrapper {
    width: 98%;
    margin: 0 auto;
  }
  .navbar-nav{
    margin-right: 0px !important;
    
  }
  .navbar-dark .navbar-nav .nav-link{
    padding: 10px;
  }

}
