.download-now-bg {
  width: 100vw;
  height: 75vh;
  /* background-image: linear-gradient(transparent 20%, black 100%),
    url("../../../public/assets/images/downloadNow/ezgif.com-gif-maker.gif"); */
  /* background-image: url("../../../public/assets/images/downloadNow/giphy\ \(9\).gif"),
    url("../../../public/assets/images/downloadNow/giphy\ \(9\).gif"); */
  background-position: left bottom, right bottom;
  background-repeat: repeat-x, no-repeat;
  background-size: contain;

  mix-blend-mode: color-dodge;

  opacity: 1;
  overflow-x: hidden;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.center-dn {
  margin-left: 62px !important;
}

.download-now-bg-lastest {
  position: relative;
}

.download-now-content {
  padding-top: 100px;
  max-width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  top: 0;
  left: 30%;
  transform: translate(-15%, 0%);
  z-index: 999999;
}

.download-now-left-h-content {
  border: 1px solid white;
  border-radius: 10px;
  padding: 50px;

  background-color: rgba(0, 0, 0, 0.4);
}

.download-now-left-h-content > :nth-child(1) {
  width: 100%;
}

.download-now-btn {
  padding-top: 30px;

}

.download-now-btn > :nth-child(1),
.download-now-btn > :nth-child(2) {
  width: 150px;
  height: 45px;
  margin: 0px 10px;
}

.download-now-btn > :nth-child(3) {
  height: 45px;
}

/* .download-now-left-h-content > :nth-child(2) > img {
  width: 130px;
  height: 60px;
} */

.download-now-right-h-content img {
  width: 300px;
  margin-left: -110px;
  margin-top: 50px;
}

.dn-onlymobile-screen {
  display: none;
}

@media screen and (max-width: 1100px) {
  .center-dn {
    margin-left: 0px !important;
  }
  .download-now-btn > :nth-child(1),
  .download-now-btn > :nth-child(2) {
    width: 120px;
    height: 45px;
    margin: 0px 10px;
  }
}

@media screen and (max-width: 768px) {
  .download-now-content {
    max-width: 100%;
  }

  .download-now-right-h-content img {
    width: 250px;
  }
  .download-now-btn{
    display: flex;
    flex-direction: column;
    row-gap: 0px;
  }
  
  .download-now-btn {
    margin-top: -60px;
    margin-left: 0px;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
  }

  .download-now-btn > :nth-child(1),
  .download-now-btn > :nth-child(2) {
    width: 130px;
    height: unset !important;
  }
  .download-now-content {
    padding-top: 50px;
    max-width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: unset;
    transform: unset;
    z-index: 999999;
  }
}

@media screen and (max-width: 600px) {
  .download-now-bg {
    width: 100%;
    height: 580px;
  }

  .download-now-content {
    max-width: 100%;

    margin-top: -87px;
  }

  .download-now-left-h-content {
    border: none;
    border-radius: 0px;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 15px;
  }

  .download-now-left-h-content > :nth-child(1) {
    width: 65%;
  }


  .download-now-btn > :nth-child(3) {
    height: 30px;
    display: none !important;
  }

  .download-now-right-h-content {
    width: 140px;
    margin-left: -69px;
    margin-top: 50px;
    display: none !important;
  }

  .dn-onlymobile-screen {
    display: block !important;
    width: 300px;
    padding: 10px;
  }
}

@media screen and (max-width: 391px) {
  .download-now-bg {
    width: 100%;
    height: 450px;
  }

  /* .download-now-left-h-content {
    padding: 10px;
  }

  .download-now-left-h-content > :nth-child(1) {
    width: 80%;
  }

  .download-now-btn > :nth-child(1),
  .download-now-btn > :nth-child(2) {
    width: 53px;
    height: 25px;
    margin: 0px 3px;
  }

  .download-now-btn > :nth-child(3) {
    height: 27px;
  }

  .download-now-right-h-content img {
    width: 130px;
    margin-top: 30px;
  } */
}

@media screen and (max-width: 360px) {
  .download-now-bg {
    width: 100%;
    height: 360px;
  }

  .download-now-btn > :nth-child(1),
  .download-now-btn > :nth-child(2) {
    width: 110px;
    height: 50px;
  }
}

@media screen and (max-width: 350px) {
  .download-now-btn > :nth-child(1),
  .download-now-btn > :nth-child(2) {
    width: 106px;
    height: 45px;
  }
}

@media screen and (min-width: 1919px) {
  .download-now-content {
    padding-top: 100px;
    max-width: 60%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -29px;
    left: 30%;
    transform: translate(-15%, 0%);
    z-index: 999999;
  }
  .download-now-left-h-content {
    border: 1px solid white;
    border-radius: 10px;
    padding: 84px 60px;
    background-color: rgba(0, 0, 0, 0.8);
  }
  .download-now-right-h-content img {
    width: 450px;
    margin-left: -142px;
    margin-top: 50px;
  }
}

@media screen and (min-width: 2500px) {
  .download-now-content {
    position: absolute;
    top: 0;
    left: 35%;
    transform: translate(-15%, 0%);
    z-index: 999999;
  }
  .download-now-bg {
    width: 100vw;
    height: 75vh;
    /* background-image: linear-gradient(transparent 20%, black 100%),
    url("../../../public/assets/images/downloadNow/ezgif.com-gif-maker.gif"); */
    /* background-image: url("../../../public/assets/images/downloadNow/giphy\ \(9\).gif"),
      url("../../../public/assets/images/downloadNow/giphy\ \(9\).gif"); */
    background-repeat: repeat-x, repeat-x;
  }
}

@media (min-width: 900px) and (max-width: 1000px) {
  .download-now-content {
    padding-top: 0px !important;
  }
}

[data-aos="new-animation"] {
  opacity: 0;
  transition-property: transform, opacity;
}
[data-aos="new-animation"].aos-animate {
  opacity: 1;
}

@media screen and (min-width: 1200px) {
  [data-aos="new-animation"] {
    transform: translateX(100px);
  }
  [data-aos="new-animation"].aos-animate {
    transform: translateX(0);
  }
}
